.nav-primary {
  border-bottom: 1px solid $c-primary;
  background-color: rgba($white, 0.2);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  transition: $transition;
  .navbar-collapse {
    flex-grow: 0;
  }
}

.nav-primary.on {
  transition: $transition;
  background-color: white;
  box-shadow: $s-boxshadow;
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggle {
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.navbar-toggle {
  border: none;
  background: transparent !important;
}

.navbar-toggle .icon-bar {
  background-color: $c-primary;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar {
	width: 22px;
	transition: all 0.2s;
}

/* ANIMATED X */
.navbar-toggle.x .icon-bar:nth-of-type(1) {
	transform: rotate(45deg);
	transform-origin: 10% 10%;
}

.navbar-toggle.x .icon-bar:nth-of-type(2) {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggle.x .icon-bar:nth-of-type(3) {
	transform: rotate(-45deg);
	transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
	transform: rotate(0);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
	transform: rotate(0);
}
/* END ANIMATED X */