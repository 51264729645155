.c-primary {
  color: $c-primary !important;
}

.c-secondary {
  color: $c-secondary !important;
}

.c-white {
  color: white;
}

.b-white {
  background-color: white;
}

.b-primary {
  background-color: $c-primary;
}

.b-secondary {
  background-color: $c-secondary;
}