.iplay {
  display: inline-flex;
  position: relative;
}

.iplay-item {
  // position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  // transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  line-height: 1;
}

.iplay-hover {
  display: inline-flex;
  position: relative;
  align-items: center;
  transition: $transition;
  &:hover {
    span {
      color: #ccc;
      border-color: #ccc;
    }
  }
}



.iplay-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  color: #fa360a;
  line-height: 48px;
  text-align: center;
  position: relative;
  transition: $transition;

  &::before, &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    animation: pulse-border 1500ms ease-out infinite;
    z-index: -1;
  }

  &::before {
    width: 90px;
    height: 90px;
  }
  &::after {
    width: 74px;
    height: 74px;

  }
}

.iplay-i {
  margin-left: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.iplay-caption {
  position: absolute;
  right: -110px;
  color: $c-secondary;
  border-bottom: 1px solid $c-secondary;
  font-weight: bolder;
  font-size: 1rem;
}

@keyframes pulse-border {
  0% {
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(.8);
    opacity:1
  }
  100% {transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.1);
    opacity:0
  }
}

@keyframes pulse-border-2{
  0% {
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.1);
    opacity:1
  }
  100% {
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity:0
  }
}