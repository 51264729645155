.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  .carousel-inner {
    height: 100vh;
  }
  .carousel-indicators {
    margin-bottom: 5rem;
  }
  .carousel-item {
    height: 100vh;
  }
  .caption {
    color: $c-primary;
  }
  .container-hero {
    position: absolute;
    top: 350px;
    left: 0;
    right: 0;
  }
}
.carousel-hero--image {
  height: 100vh;
  width: 100%;
}

.img-1 {
  background-image: url('../assets/slide.jpg');
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(lg) {
    background-position: -290px;
  }
  @include media-breakpoint-down(md) {
    background-position: 0px;
  }
}

.img-2 {
  background-image: url('../assets/slide-2.jpg');
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(lg) {
    background-position: -290px;
  }
  @include media-breakpoint-down(md) {
    background-position: 0px;
  }
}

.img-3 {
  background-image: url('../assets/slide-5.jpg');
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(lg) {
    background-position: -290px;
  }
  @include media-breakpoint-down(md) {
    background-position: 0px;
  }
}
