/**
 * Change animation duration
 */
 .animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@-webkit-keyframes fadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@-webkit-keyframes fadeInDown {
  0% {
      opacity: 0;
      transform: translate3d(0,-100%,0)
  }

  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none
  }
}

@keyframes fadeInDown {
  0% {
      opacity: 0;
      transform: translate3d(0,-100%,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@-webkit-keyframes fadeInDownBig {
  0% {
      opacity: 0;
      transform: translate3d(0,-2000px,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}

@keyframes fadeInDownBig {
  0% {
      opacity: 0;
      transform: translate3d(0,-2000px,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@-webkit-keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translate3d(-100%,0,0)
  }

  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none
  }
}

@keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translate3d(-100%,0,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      transform: translate3d(-2000px,0,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}

@keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      transform: translate3d(-2000px,0,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@-webkit-keyframes fadeInRight {
  0% {
      opacity: 0;
      transform: translate3d(100%,0,0)
  }

  to {
      opacity: 1;
      transform: none
  }
}