.backtotop {
  display: inline-block;
  background-color: $c-secondary;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 50px;
  right: 30px;
  transition: background-color .3s, 
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  &::after {
    content: "\f077";
    font-family: "font awesome 6 free";
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: #333;
  }
  &:active {
    background-color: #555;
  }
}

.backtotop.show {
  opacity: 1;
  visibility: visible;
}
