$c-primary: #3E183A;
$c-secondary: #5FA2B6;
$rgb-primary: 62, 24, 58;
$primary: #3E183A;


// social
$facebook: #3B5998;
$instagram: #DD2A7B;
$youtube: #E62117;
$whatsapp: #25D366;
$twitter: #00ACEE;
// shadows 
$s-boxshadow: 0 5px 10px 0 rgba(0,0,0,0.4);

// transition

$transition: all 0.3s ease;