// Vendors
@import "@/styles/components/variables";
@import "@/styles/vendors/bootstrap";
@import "@/styles/vendors/font-awesome";
@import "@/styles/vendors/google-fonts";
// @import "@/styles/vendors/owl.carousel";

// Componentes
@import "@/styles/components/sizes";
@import "@/styles/components/colors";
@import "@/styles/components/nav";
@import "@/styles/components/hero";
@import "@/styles/components/text-anime";
@import "@/styles/components/cardbox";
@import "@/styles/components/link";
@import "@/styles/components/mosaic";
@import "@/styles/components/carousel-swipe";
@import "@/styles/components/paralax";
@import "@/styles/components/titles";
@import "@/styles/components/iplay";
@import "@/styles/components/backtotop";

body {
  font-family: 'Montserrat', sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#map {
  height: 600px;
}

.spacer-pt--default {
  padding-top: 250px;
  @include media-breakpoint-down(xl) {
    padding-top: 400px;
  }
  @include media-breakpoint-down(lg) {
    padding-top: 500px;
  }
  @include media-breakpoint-down(md) {
    padding-top: 520px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 920px;
  }
}

.spacer-mb--default {
  margin-bottom: 60px;
}

.title-border {
  color: $c-primary;
  border-bottom: 2px solid $c-primary;
}

.section-spacer--default {
  padding-top: 60px;
  padding-bottom: 60px;
}

.servicos {
  background-image: url('../assets/bg-roll.jpg');
  background-size: cover;
}

.equipe {
  background-image: url('../assets/shape30.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.footer {
  background: linear-gradient(90deg, rgba(62, 24, 58, 1) 19%, rgba(34, 176, 202, 1) 100%);
}

#instagram {
  .wx-title {
    display: none;
  }
}

.progress{
  border-radius: 0;
  overflow: visible;
  background-color: transparent;
}

.bars-default {
  width: 0;
  background: rgb(62,24,58);
  background: linear-gradient(90deg, rgba(62,24,58,0.3) 0%, rgba(95,162,182,0.3) 50%, rgba(95,162,182,0) 100%);
  transition: width 2.5s ease-in-out;
}

.cardA.in-view {
  animation: fadeIn 2s;
}

.cardB.in-view {  
  animation: fadeInLeftBig 2s;
}

.cardC.in-view {
  animation: fadeInDown 2s;
}

.barsload.in-view {
  width: 100%;
}