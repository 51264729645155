.cardbox-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardbox-flexSwipe {
  flex-wrap: nowrap;
}

.cardbox-absolute {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 2;
}

.cardbox.perfil {
  .imgbox {
    width: 100px;
    height: 100px;
  }
  img {
    max-width: 98px;
  }
  .title {
    margin-bottom: 0;
  }
}

.cardbox {
  background-color: $c-secondary;
  flex: 0 1 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .imgbox {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 41px;
  }
  p {
    font-weight:lighter;
  }
}

.cardbox-nospace {
  flex: 1;
}

.cardbox-saparate {
  &:not(:last-child) {
    border-bottom: 1px solid $white;
  }
}

.cardbox-style {
  margin: 0;
  background-color: transparent;
  flex: 1;
  &:not(:last-child) {
    border-right: 1px solid $white;
  }
  @include media-breakpoint-down(xl) {
    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid $white;
    }
    &:nth-child(1),
    &:nth-child(3) {
      border-right: 1px solid $white;
    }
    &:nth-child(2),
    &:nth-child(4) {
      border-right: none;
    }
  }
  @include media-breakpoint-down(sm) {
    &:nth-child(1),
    &:nth-child(3) {
      border-right: none;
    }
    &:nth-child(2),
    &:nth-child(4) {
      border-right: none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $white;
    }
  }
}

.cardbox-style1 {
  @include media-breakpoint-down(xl) {
    flex: 0 49%;
    margin: 1px 1px 5px 1px;
  }
  @include media-breakpoint-down(sm) {
    flex: 0 100%;
    margin: 0 0 5px 0;
  }
}

.cardbox-style {
  @include media-breakpoint-down(xl) {
    flex: 0 50%;
  }
  @include media-breakpoint-down(sm) {
    flex: 0 100%;
  }
}

.cardbox-style2 {
  background-color: white;
  border: 1px solid #ccc;
  min-width: 300px;
}