.paralax {
  height: 630px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.paralax-background {
  background-image: url('../assets/bg-booking.jpg');
}