.link {
	transition: $transition;
  text-decoration: none;
		&:hover {
			text-decoration: none;
		}
}

.link-footer {
  color: #fff;
  &:hover {
    color: $secondary;
  }
}

.link-nav {
  text-transform: uppercase;
  color: #333;
	font-size: 16px;
	padding: 10px 15px;
	font-weight: 500;
  &:hover {
    color: $c-secondary;
  }
}

.link-facebook,
.link-whatsapp,
.link-youtube,
.link-instagram {
  color: $c-primary;
}

.link-facebook {
  &:hover {
    color: $facebook;
  }
}

.link-whatsapp {
  &:hover {
    color: $whatsapp;
  }
}

.link-youtube {
  &:hover {
    color: $youtube;
  }
}

.link-instagram {
  &:hover {
    color: $instagram;
  }
}

.link-primary {
  color: $c-primary;
  &:hover {
    color: $c-secondary;
  }
}

.link-white {
  color: white;
  &:hover {
    color: #cccccc;
  }
}

.link-card {
  display: flex;
  .caption-card {
    background-color:rgba(10,45,61,0.7);
    transition: background-color 0.5s ease;
    &:hover {
      background-color:rgba(10,45,61,0);
    }
  }
}

.active {
  font-weight: bold;
  border-bottom: 4px solid $c-secondary;
  color: $c-primary;
}